@if (show) {
  <div class="modal">
    <div class="modal-container">
      <img
        (click)="onClose()"
        class="modal-container-close"
        src="assets/img/icon_white_close.png"
        alt="" />
      <div class="modal-container-info">
        <div class="modal-container-info__title">
          Pronto tendremos lista esta funcionalidad y te invitamos a que te
          registres en el Fintek Club para que accedas a nuestros beneficios
        </div>
      </div>
      <div class="modal-container-actions">
        <fc-button type="primary" (fcClick)="onJoin()">Únete al club</fc-button>
      </div>
    </div>
  </div>
}
